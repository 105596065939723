* {padding: 0;margin: 0}

html, body, #root,.ant-layout {
  width: 100%;
  height: 100%;
}

.app_loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    height: 40px;
    width: auto;
  }
}

.layout_header {
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controller {
  display: flex;
  flex-direction: column;
  max-width: 95vw;
  width: 100%;
  margin: 12px auto;
}

.table_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: 12px;
  }
}

.page-header {
  background-color: #FFF;
  margin-bottom: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.panel {
  background: #FFF;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.actions_wrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  button + button {
    margin-left: 12px;
  }
}

.qcover {
  max-width: 200px;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.question_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  .question_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFF;
    padding: 12px 8px;
    & + .question_row {
      border-top: 1px solid #e2e2e2;
    }
    .title {
      width: 230px;
      margin-right: 12px;
    }
    .value {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .question_row.user {
    .ant-avatar {
      margin-right: 12px;
    }
  }

  .question_row.info_questions {
      margin-top: 24px;
      margin-bottom: 24px;
      border-top: 0;
      align-items: flex-start;
      .question_video {
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        video {
          max-width: 440px;
          width: 100%;
          margin-bottom: 12px;
        }
      }
    .question_fields  {
      display: flex;
      flex-direction: column;
      flex: 1;
      .ant-form-item {
        width: 100%;
      }
    }
  }

  .question_row.active, .question_row.in_export {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .question_row.cover {
    align-items: flex-start;
    .cover {
      margin-right: 12px;
      img {
        max-width: 440px;
        min-width: 440px;
        width: 100%;
        height: auto;
      }
    }
    .covers_list {
      width: 100%;
    }
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .image {
        width: 80px;
        height: 80px;
        display: flex;
        min-width: 80px;
        min-height: 80px;
        margin-top: 12px;
        flex-direction: column;
        border-radius: 3px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:hover {
          opacity: 0.7;
        }
        & + .image {
          margin-left: 12px;
        }
      }
    }
  }

  .question_row.btns {
    margin-top: 12px;
    justify-content: center;

  }
}


.questions_export {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    & + .column {
      margin-left: 24px;
    }
  }
}

.q_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 4px;
  }
}

.export_link {
  margin-bottom: 24px;
  background: #FFF;
  padding: 8px;
}